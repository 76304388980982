import { TableComponentNames } from '@/components/tables/components/names';
import TableGeneratorEventDossier from '@/components/tables/components/generator.event.dossier.vue';
import TableGeneratorEventFeatures from '@/components/tables/components/generator.event.features.vue';
import TableGeneratorEventTimeCamera from '@/components/tables/components/generator.event.time.camera.vue';
import TableGeneratorObjectThumbnail from '@/components/tables/components/generator.object.thumbnail.vue';
import TableGeneratorDateTime from '@/components/tables/components/generator.date.time.vue';
import TableGeneratorAreaNameDescription from '@/components/tables/components/generator.area.name.description.vue';
import TableGeneratorCameraScreenshot from '@/components/tables/components/generator.camera.screenshot.vue';
import TableGeneratorAreaTriggerDescription from '@/components/tables/components/generator.area-trigger.description.vue';
import TableGeneratorAreaRecordDescription from '@/components/tables/components/generator.area-record.description.vue';
import TableGeneratorAreaTriggerScreenshot from '@/components/tables/components/generator.area-trigger.screenshot.vue';
import TableGeneratorPolygonView from '@/components/tables/components/generator.polygon.view.vue';
import TableGeneratorAuthSessionButtons from '@/components/tables/components/generator.auth-sessions.buttons.vue';

export const TableComponents = {
  [TableComponentNames.EventDossier]: TableGeneratorEventDossier,
  [TableComponentNames.EventFeatures]: TableGeneratorEventFeatures,
  [TableComponentNames.EventTimeCamera]: TableGeneratorEventTimeCamera,
  [TableComponentNames.ObjectThumbnail]: TableGeneratorObjectThumbnail,
  [TableComponentNames.AreaNameAndDescription]: TableGeneratorAreaNameDescription,
  [TableComponentNames.DateTime]: TableGeneratorDateTime,
  [TableComponentNames.CameraScreenshot]: TableGeneratorCameraScreenshot,
  [TableComponentNames.AreaTriggerDescription]: TableGeneratorAreaTriggerDescription,
  [TableComponentNames.AreaRecordDescription]: TableGeneratorAreaRecordDescription,
  [TableComponentNames.AreaTriggerScreenshot]: TableGeneratorAreaTriggerScreenshot,
  [TableComponentNames.AreaRecordDescription]: TableGeneratorAreaRecordDescription,
  [TableComponentNames.PolygonView]: TableGeneratorPolygonView,
  [TableComponentNames.AuthSessionButtons]: TableGeneratorAuthSessionButtons
};
