








import Vue from 'vue';
import { Prop, Ref, Component, Watch } from 'vue-property-decorator';

const Colors = {
  PointFill: 'rgba(255, 255, 255, 0.9)',
  PointBorder: 'rgb(0, 255, 0)',
  LineDefault: 'rgb(0, 200, 0)',
  LineActive: 'rgb(169,255,169)',
  PolygonFill: 'rgba(0, 0, 0, 0.3)',
  PointDraggedFill: 'rgba(55, 255, 55, 0.9)',
  PointOverFill: 'rgba(255, 55, 55, 0.9)'
};

const Sizes = {
  PointSize: 12,
  LineWidth: 2
};

type Rect = {
  width: number;
  height: number;
  x: number;
  y: number;
};

@Component({
  name: 'polygon-view'
})
export default class PolygonView extends Vue {
  @Ref('canvasContainer')
  canvasContainer;
  canvasRect?: Rect;
  canvas;
  canvasContext;
  polygonPath;

  @Prop({ required: true, type: String })
  image!: any;

  @Prop({ type: Array })
  points: any;

  @Prop({ required: true, type: Number })
  originalWidth!: number;

  @Prop({ required: true, type: Number })
  originalHeight!: number;

  offsets: any = { x: 0, y: 0 };

  @Watch('points')
  pointsHandler(v, p) {
    this.resize();
  }

  mounted() {
    this.createCanvas();
    this.resize();
  }

  createCanvas() {
    this.canvas = document.createElement('canvas');
    this.canvasContext = this.canvas.getContext('2d');
    this.canvasContainer.appendChild(this.canvas);
  }

  resizeCanvas() {
    const sourceRect = this.canvasContainer.getBoundingClientRect();
    this.canvasRect = { width: sourceRect.width, height: sourceRect.height, x: 0, y: 0 };
    this.canvas.width = this.canvasRect.width;
    this.canvas.height = this.canvasRect.height;
  }

  resize() {
    this.resizeCanvas();
    this.nextTickDraw();
  }

  nextTickDraw() {
    this.$nextTick(this.draw);
  }

  get scaleFactor() {
    const { canvasRect } = this,
      canCompute = canvasRect;
    if (!canCompute) return 1;

    const scaleFactor = Math.max(this.originalWidth / canvasRect.width, this.originalHeight / canvasRect.height);
    return scaleFactor;
  }

  getPointsToInternal() {
    return (this.points || []).map((v) => [v[0] / this.scaleFactor + this.offsets.x, v[1] / this.scaleFactor + this.offsets.y]);
  }

  get internalPoints() {
    return this.getPointsToInternal();
  }

  drawLine(startPoint, endPoint) {
    const ctx = this.canvasContext;
    ctx.setLineDash([]);
    ctx.lineWidth = Sizes.LineWidth;
    ctx.strokeStyle = Colors.LineDefault;
    ctx.beginPath();
    ctx.moveTo(startPoint[0], startPoint[1]);
    ctx.lineTo(endPoint[0], endPoint[1]);
    ctx.stroke();
    ctx.closePath();
  }

  draw() {
    if (!this.canvasRect) return;
    this.canvasContext.clearRect(0, 0, this.canvasRect.width, this.canvasRect.height);
    this.fillPolygon();

    for (let i = 0; i < this.internalPoints.length; i++) {
      const startPoint = this.internalPoints[i];
      const nextPoint = this.internalPoints[i + 1];
      if (startPoint && nextPoint) this.drawLine(startPoint, nextPoint);
      else {
        this.drawLine(startPoint, this.internalPoints[0]);
      }
    }
  }

  fillPolygon() {
    const ctx = this.canvasContext;
    const startPoint = this.internalPoints[0];

    this.polygonPath = null;
    if (this.internalPoints.length < 3) return;

    let polygonPath = new Path2D();

    polygonPath.moveTo(startPoint[0], startPoint[1]);
    for (let i = 1; i < this.internalPoints.length; i++) {
      const point = this.internalPoints[i];
      polygonPath.lineTo(point[0], point[1]);
    }
    polygonPath.lineTo(startPoint[0], startPoint[1]);
    polygonPath.closePath();

    ctx.fillStyle = Colors.PolygonFill;
    ctx.fill(polygonPath);
    this.polygonPath = polygonPath;
  }
}
