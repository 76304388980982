



























import Vue from 'vue';
import Component from 'vue-class-component';
import EventReaction from '@/components/events/reaction.vue';
import DossiersItem from '@/components/events/dossier-item.vue';
import ObjectThumbnail from '@/components/objects/thumbnail.vue';
import DossierListsInline from '@/components/dossier-lists/inline.vue';
import { TableComponentNames } from '@/components/tables/components/names';
import { Prop } from 'vue-property-decorator';

@Component({
  name: TableComponentNames.EventDossier,
  components: { DossierListsInline, ObjectThumbnail, DossiersItem, EventReaction }
})
export default class TableGeneratorEventDossier extends Vue {
  @Prop({ type: Object, required: true })
  state!: any;

  @Prop({ type: Object, required: true })
  item!: any;

  @Prop({ type: String, required: true })
  objectsType!: string;

  getItemLink(item) {
    return `/events/${this.objectsType}/${encodeURIComponent(item.id)}/`;
  }

  showImage(url, box) {
    this.$store.dispatch('showImage', { src: url, box: box });
  }

  getBox(event) {
    return {
      x: event.frame_coords_left,
      y: event.frame_coords_top,
      w: event.frame_coords_right - event.frame_coords_left,
      h: event.frame_coords_bottom - event.frame_coords_top
    };
  }
}
