








import { Prop, Component, Vue } from 'vue-property-decorator';
import { TableComponentNames } from '@/components/tables/components/names';
import { IArea } from '@/store/areas/areas';
import { areasModule } from '@/store';
import { Weekdays, WeekdaysI18n, WeekdaysShortI18n, compareWeekdayByWeight } from '@/components/areas/areas/trigger_calendar_weekdays';

@Component({
  name: TableComponentNames.AreaNameAndDescription
})
export default class TableGeneratorAreaNameDescription extends Vue {
  @Prop({ required: true })
  routeName!: string;

  @Prop({ required: true })
  item!: IArea;

  get routerItemLink() {
    return areasModule.getItemRoute(this.item);
  }

  get description() {
    const { trigger_count_gt, trigger_count_lt, trigger_time } = this.item;
    const triggerTypeI18n = trigger_count_gt !== null ? 'more_then' : 'less_then';
    const triggerValue = trigger_count_gt !== null ? trigger_count_gt : trigger_count_lt;
    const result =
      this.$tf(triggerTypeI18n) +
      ' ' +
      this.$tmf('people_mf', { count: triggerValue }) +
      ', ' +
      this.$tt('more_than_duration') +
      ' ' +
      this.$tmf('duration_mf', { duration: trigger_time });
    return result;
  }

  get cameraAndCameraGroup() {
    const { cameras, camera_groups } = this.item;
    return this.computeCameraNameById(cameras) + ' / ' + this.computeCameraGroupNameById(camera_groups);
  }

  get schedule() {
    const { trigger_calendar } = this.item;
    return trigger_calendar?.reduce((str, calendar) => {
      let weekdays = calendar.weekdays?.sort(compareWeekdayByWeight);
      const copyWeekdays = [...weekdays];
      const separator = str ? '; ' : '';
      return str + separator + this.sortWeekdaysWithIntervals(copyWeekdays) + calendar.start_at + ' - ' + calendar.end_at;
    }, '');
  }

  computeI18nWeekday(weekday) {
    return WeekdaysI18n[Weekdays[weekday]];
  }

  computeCameraNameById(cameraId) {
    return this.$store.state.cameras.items.find((camera) => camera.id == cameraId)?.name || '-';
  }

  computeCameraGroupNameById(cameraGroupId) {
    return this.$store.state.camera_groups.items.find((camera_group) => camera_group.id == cameraGroupId)?.name || '-';
  }

  getWeekdaysShortI18n(weekday) {
    return this.$tf('date.' + WeekdaysShortI18n[weekday]);
  }

  sortWeekdaysWithIntervals(weekdays) {
    let result = '',
      i = 0;
    while (i < weekdays.length) {
      let firstWeekday, index;
      if (compareWeekdayByWeight(weekdays[i + 1], weekdays[i]) === 1 && compareWeekdayByWeight(weekdays[i + 2], weekdays[i + 1]) === 1) {
        firstWeekday = weekdays[i];
        index = i;
        while (compareWeekdayByWeight(weekdays[index + 1], weekdays[index]) === 1) {
          weekdays.splice(index, 1);
          if (compareWeekdayByWeight(weekdays[index + 1], weekdays[index]) !== 1) {
            const separator = index + 1 < weekdays.length ? ', ' : ' ';
            result += this.getWeekdaysShortI18n(firstWeekday) + '-' + this.getWeekdaysShortI18n(weekdays[index]) + separator;
            i++;
          }
        }
      } else {
        const separator = i + 1 < weekdays.length ? ', ' : ' ';
        result += this.$tf('date.' + WeekdaysShortI18n[weekdays[i]]) + separator;
        i++;
      }
    }
    return result;
  }
}
