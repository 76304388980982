export const TableComponentNames = {
  EventDossier: 'table-generator-event-dossier',
  EventFeatures: 'table-generator-event-features',
  EventTimeCamera: 'table-generator-event-time-camera',
  ObjectThumbnail: 'table-generator-object-thumbnail',
  ObjectThumbnailById: 'table-generator-object-thumbnail-by-id',
  NameWithComment: 'table-generator-name-with-comment',
  CameraScreenshot: 'table-generator-camera-screenshot',
  CameraGroup: 'table-generator-camera-group',
  ActiveComponent: 'table-generator-active-component',
  CameraState: 'table-generator-camera-state',
  CameraStatus: 'table-generator-camera-status',
  Actions: 'table-generator-actions',
  DateTime: 'table-generator-date-time',
  AreaNameAndDescription: 'table-generator-area-name-and-description',
  AreaTriggerDescription: 'table-generator-area-trigger-description',
  AreaTriggerScreenshot: 'table-generator-area-trigger-screenshot',
  AreaRecordDescription: 'table-generator-area-record-description',
  PolygonView: 'table-generator-polygon-view',
  AuthSessionButtons: 'table-generator-auth-session-buttons'
};
