







import Component from 'vue-class-component';
import Vue from 'vue';
import { TableComponentNames } from '@/components/tables/components/names';
import PolygonView from '@/components/polygon-draw/view.vue';
import FailedScreenshot from '@/components/common/failedScreenshot.vue';
import { Prop, Watch } from 'vue-property-decorator';
import { IArea } from '@/store/areas/areas/index';
import axios from 'axios';

interface IDimension {
  width: number;
  height: number;
}

@Component({
  name: TableComponentNames.PolygonView,
  components: { PolygonView, FailedScreenshot }
})
export default class TableGeneratorPolygonView extends Vue {
  @Prop({ required: true, type: Object })
  item!: IArea;

  width = 160;
  height = 90;

  defaultWidth = 1024;
  defaultHeight = 768;

  imageData: string | null = null;
  dimensions: IDimension | null = null;

  get containerStyle() {
    return { width: `${this.width}px`, height: `${this.calculatedHeight}px` };
  }

  get calculatedHeight() {
    return this.dimensions ? Math.round((this.dimensions.height * this.width) / this.dimensions.width) : this.height;
  }

  get defaultCameraRoi(): any {
    return this.item.camera_roi ? this.item.camera_roi[0] : null;
  }

  get defaultPoints(): number[][] {
    return [
      [0, 0],
      [this.originalWidth, 0],
      [this.originalWidth, this.originalHeight],
      [0, this.originalHeight]
    ];
  }

  async loadDimensions() {
    this.dimensions = await this.getCamerasDimensions(this.cameraId);
  }

  async getCamerasDimensions(id: number): IDimension {
    let items: Map<number, IDimension> = this.$store.state.cache.camera_dimensions || {};
    let currentDimensions: Map<number, IDimension> = this.$store.state.cameras.items.reduce((m, v) => {
      if (v?.health_status?.statistic?.frame_width) {
        const { frame_width, frame_height } = v.health_status.statistic;
        m[v.id] = { width: frame_width, height: frame_height };
      }
      return m;
    }, {});
    items = { ...items, ...currentDimensions };
    if (!items[id]) {
      items[id] = await this.getOriginalScreenshotDimensions();
    }
    this.$store.state.cache.camera_dimensions = items;
    return items[id];
  }

  get originalWidth(): number {
    return this.dimensions?.width || this.defaultWidth;
  }

  get originalHeight(): number {
    return this.dimensions?.height || this.defaultHeight;
  }

  get cameraId(): number {
    return this.defaultCameraRoi?.camera;
  }

  get points(): number[][] {
    return this.defaultCameraRoi?.roi ? this.defaultCameraRoi.roi : this.defaultPoints;
  }

  get cameraScreenshotUrl(): string {
    return this.$store.state.config.server.url + `cameras/${this.cameraId}/screenshot/` + (this.width ? '?width=' + this.width : '');
  }

  get cameraOriginalScreenshotUrl(): string {
    return this.$store.state.config.server.url + `cameras/${this.cameraId}/screenshot/?width=-1&height=-1`;
  }

  mounted() {
    if (this.cameraId) {
      this.loadScreenshot();
      this.loadDimensions();
    }
  }

  @Watch('cameraId')
  cameraIdHandler(v, p) {
    this.imageData = null;
    if (this.cameraId) {
      this.loadScreenshot();
      this.loadDimensions();
    }
  }

  getOriginalScreenshotDimensions(): Promise<IDimension> {
    return axios({
      url: this.cameraOriginalScreenshotUrl,
      responseType: 'blob',
      headers: {
        Authorization: 'Token ' + encodeURIComponent(this.$store.state.app.token)
      }
    })
      .then((v) => {
        let width = 0,
          height = 0,
          resolveHandler: any = null,
          rejectHandler: any = null,
          resultPromise = new Promise((r, j) => {
            resolveHandler = r;
            rejectHandler = j;
          });

        if (v.data && v.data.size) {
          const image = new Image();
          image.onload = () => {
            width = image.naturalWidth;
            height = image.naturalHeight;
            resolveHandler({ width, height });
          };
          image.src = URL.createObjectURL(v.data);
        } else {
          throw new Error('[image:loaded] empty data error');
        }
        return resultPromise;
      })
      .catch((e) => {
        console.warn('[poly-view-error]:get-original ', e);
      }) as Promise<IDimension>;
  }

  loadScreenshot() {
    if (this.imageData) return;
    return axios({
      url: this.cameraScreenshotUrl,
      method: 'post',
      responseType: 'blob',
      headers: {
        Authorization: 'Token ' + encodeURIComponent(this.$store.state.app.token)
      }
    })
      .then((v) => {
        if (v.data && v.data.size) {
          this.imageData = URL.createObjectURL(v.data);
        } else {
          throw new Error('[image:loaded] empty data error');
        }
      })
      .catch((e) => {
        console.warn('[poly-view-error] ', e);
      });
  }
}
