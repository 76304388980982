



import Component from 'vue-class-component';
import Vue from 'vue';
import ObjectThumbnail from '@/components/objects/thumbnail.vue';
import { TableComponentNames } from '@/components/tables/components/names';
import { Prop } from 'vue-property-decorator';

@Component({
  name: TableComponentNames.ObjectThumbnail,
  components: { ObjectThumbnail }
})
export default class TableGeneratorObjectThumbnail extends Vue {
  @Prop({ required: true })
  item!: any;

  previewHandler() {
    const data = { src: this.fullImage, box: this.bbox };
    if (this.item.body_bboxes) data.silhouettes_bbox = this.$filters.polyArraysToRects(this.item.body_bboxes);
    this.$store.dispatch('showImage', data);
  }

  get fullImage() {
    return this.item.fullframe || this.item.source_photo; // fullframe -> events; source_photo -> case faces
  }

  get hasBBox() {
    return this.item.frame_coords_top > 0 || this.item.frame_coords_bottom > 0;
  }

  get bbox() {
    return this.hasBBox
      ? {
          x: this.item.frame_coords_left,
          y: this.item.frame_coords_top,
          w: this.item.frame_coords_right - this.item.frame_coords_left,
          h: this.item.frame_coords_bottom - this.item.frame_coords_top
        }
      : null;
  }
}
