








































import Vue from 'vue';
import { TableComponents } from '@/components/tables/components';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { BaseItemsState } from '@/definitions/base.state';
import { BaseItemsStateModule } from '@/definitions/base.items.state.module';

export interface ITableColumn {
  component?: string;
  label_i18n: string;
  prop?: string;
  'class-name'?: string;
  width?: string | number;
  'min-width'?: string | number;
  formatter?: (item: any, column?: any) => string;
  align?: string;
  sortable?: boolean | string;
  'sort-method'?: (item0: any, item1: any) => number;
}

@Component({
  name: 'table-generator'
})
export default class TableGenerator extends Vue {
  @Prop({ required: true })
  state!: BaseItemsStateModule<any, any>;

  @Prop({ required: true })
  schema!: ITableColumn[];

  @Prop({ default: false })
  selection;

  @Prop({ type: String })
  objectsType?: string;

  @Prop({ type: Boolean })
  displayErrors?: boolean;

  getComponent(name: string) {
    return TableComponents[name];
  }

  getColumnProps(columnSchema: ITableColumn) {
    return { ...columnSchema, label: columnSchema.label_i18n ? this.$tf(columnSchema.label_i18n) : '' };
  }

  getComponentProps(row: any, columnSchema: ITableColumn) {
    return { item: row, state: this.state, objectsType: this.objectsType, routeName: this.state.routeName, prop: columnSchema.prop };
  }

  handleSelectionChange(v: any[]) {
    this.state.selectedItems = v;
  }

  rowClick(row, column, event) {
    if (column.type === 'selection') return;
    this.$emit('rowClick', { item: row, column, event });
  }

  rowDoubleClick(row, column, event) {
    this.$emit('rowDoubleClick', { item: row, column, event });
  }

  cellClick(row, column, cell, event) {
    this.$emit('rowCellClick', { item: row, column, cell, event });
  }

  cellDoubleClick(row, column, cell, event) {
    this.$emit('rowCellDoubleClick', { item: row, column, cell, event });
  }

  sortChangeHandler({ prop, order }) {
    const propToFieldName = {};
    const field = propToFieldName[prop] ?? prop;
    this.state.filter.current.ordering = field ? (order !== 'ascending' ? '-' : '') + field : '';
  }

  computeSpanMethod({ row, column, rowIndex, columnIndex }) {
    return {
      rowspan: 1,
      colspan: this.displayErrors && row.error ? this.schema.length : 1
    };
  }

  tableClassName({ row }) {
    return row.error ? 'error-row' : '';
  }
}
