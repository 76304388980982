






import { Prop, Component, Vue, Watch } from 'vue-property-decorator';
import { TableComponentNames } from '@/components/tables/components/names';
import FailedScreenshot from '@/components/common/failedScreenshot.vue';
import { IAreaTrigger } from '@/store/areas/triggers';
import { IAreaRecord, IAreaRecordFilter } from '@/store/areas/records';
import { areaRecordsModule } from '@/store/index';

@Component({
  name: TableComponentNames.AreaTriggerScreenshot,
  components: { FailedScreenshot }
})
export default class TableGeneratorAreaTriggerScreenshot extends Vue {
  @Prop({ required: true })
  item!: IAreaTrigger;

  record: IAreaRecord | null = null;

  @Watch('item')
  itemHandler(v, p) {
    if (v?.id !== p?.id) this.load();
  }

  mounted() {
    this.load();
  }

  async load() {
    const filter: IAreaRecordFilter = { area_trigger: [this.item.id], ordering: '-id', limit: 1 };
    this.record = null;
    try {
      const items = await areaRecordsModule.getByFilter(filter);
      this.record = items[0];
    } catch (e) {
      console.warn('[area-record] get error', e);
    }
  }
}
